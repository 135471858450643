





























































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeleteRegistrarTask,
  dispatchGetRegistrarTasks,
  dispatchKillRegistrarTask,
  dispatchRunRegistrarTask,
  dispatchStatRegistrarTasks,
  dispatchDownloadRegistrarTaskResult,
  dispatchDownloadRegistrarTaskFile,
} from '@/store/registrar/actions';
import { readRegistrarTasks } from '@/store/registrar/getters';
import { SITE_PARSER_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { IRegistrarTaskStats } from '@/interfaces/registrar';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class RegistrarTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readRegistrarTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetRegistrarTasks(this.$store);
    await dispatchStatRegistrarTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetRegistrarTasks(this.$store);
      await dispatchStatRegistrarTasks(this.$store);
      }, SITE_PARSER_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteRegistrarTask(this.$store, {id: taskId});
    await dispatchGetRegistrarTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunRegistrarTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillRegistrarTask(this.$store, {id: taskId});
  }

  public async downloadRegistrarTaskResult(taskId: number, result: string) {
    await dispatchDownloadRegistrarTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadRegistrarTaskFile(taskFile: string) {
    await dispatchDownloadRegistrarTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: IRegistrarTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

}
